export const handleObserver = (ref, setVisible) => {
    const observer = new IntersectionObserver(
        ([entry]) => {
            if (entry.intersectionRatio >= 0.3) {
                setVisible(true);
            } else {
                setVisible(false);
            }
        },
        {
            root: null,
            rootMargin: '0px',
            threshold: [0, 0.3, 1.0],
        },
    );
    if (ref) {
        observer.observe(ref);
    }
    return () => {
        observer.unobserve(ref);
    };
};

export const handleVideoObserver = (ref, isMuted, setVisible) => {
    let isPaused = false;

    const observer = new IntersectionObserver(
        ([entry]) => {
            if (
                entry.intersectionRatio > 1.0 &&
                entry.intersectionRatio < 0 &&
                !ref.paused
            ) {
                ref.pause();
                isPaused = true;
            } else if (isPaused) {
                ref.play();
                isPaused = false;
            } else if (entry.intersectionRatio >= 0.5 && isMuted) {
                setTimeout(() => {
                    setVisible(false);
                }, 4000);
            }
        },
        {
            root: null,
            rootMargin: '0px',
            threshold: [0.5, 1],
        },
    );

    if (ref) {
        observer.observe(ref);
    }
    return () => {
        observer.unobserve(ref);
    };
};
